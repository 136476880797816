import { render, staticRenderFns } from "./Gallery.vue?vue&type=template&id=65b6da65&scoped=true&"
import script from "./Gallery.vue?vue&type=script&lang=ts&"
export * from "./Gallery.vue?vue&type=script&lang=ts&"
import style0 from "./Gallery.vue?vue&type=style&index=0&id=65b6da65&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65b6da65",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesProductCard: require('/var/www/packages/theme/components/molecules/ProductCard.vue').default,MoleculesKESfCarousel: require('/var/www/packages/theme/components/molecules/KESfCarousel.vue').default})
